@media(max-width: 480px) {
    .share-dialog .MuiDialog-paperWidthXl {
        min-width: 100vw;
        overflow: hidden;
    }
}

.share-dialog .MuiPaper-root {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
}

.share-dialog canvas {
    overflow: inherit;
}

@media(max-height: 400px) {
    .share-dialog .MuiPaper-root {
        margin-bottom: 0;
        margin-top: 0;
        max-height: calc(100% - 8px);
    }
}
