
.floating-product-info {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  right: 20px;

  display: flex;
  align-items: center;
}

.product-swatch {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.product-swatch img {
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
}

.product-name {
  font-size: 2.0vh;
  color: #fff;
  text-shadow: 1px 1px rgba(0,0,0,0.5);
  text-transform: uppercase;
  user-select: none;
}

.column-or-row {
  display: flex;
  align-items: flex-end;
}

.grow-enter {
  height: 20px;
  cursor:pointer;
  /* transform: translateX(50px); */
}

.grow-enter.grow-enter-active {
  height: 40px;
  transition: all 400ms;
}
.grow-enter-done {
  height: 40px;
}

.grow-exit {
  height: 40px;
  opacity: 1;
}
.grow-exit-active {
  height: 20px;
  opacity: 0;
  /* transform: translateX(100px); */
  transition: all 400ms ease-out;  
}

.squash-enter {
  height: 20px;
}
/* 
.squash-enter.squash-enter-active {
  background-color: var(--my-css-var);
  transition: all 600ms;
} */
.squash-enter-done {
  height: 20px;
}

.squash-exit {
  height: inherit;
  opacity: 1;
}

.product-swatch {
  cursor: pointer;
  width: 40px;
  min-height: 20px;
  bottom: 0;
  margin-left: 10px;
  margin-right: 0px;
  border: 1px solid #ffffff;
  box-shadow: 0 3px 10px 1px;
}

.product-swatch-full {
  cursor: pointer;
  width: 40px;
  min-height: 20px;
  bottom: 0;
  margin-left: 10px;
  border: 1px solid #ffffff;
  box-shadow: 0 3px 10px 1px;
}

.product-swatch-full.empty {
  visibility: hidden;
}

.product-swatch img {
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
}

.product-name {
  font-size: 2.0vh;
  align-self: flex-end;
  color: #fff;
  cursor: pointer;
  text-shadow: 1px 1px rgba(0,0,0,0.5);
  text-transform: uppercase;
  user-select: none;
}
.product-name-mobile {
  display: none;
}

@media(max-device-width: 480px) { 
  .floating-product-info {
    justify-content: center;
    align-items: center;
    bottom: 100px;
    margin: 0px;
    flex-direction: column;
  }
  .product-name {
    display: none
  }
  .column-or-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .product-name-mobile {
    margin-top: 10px;;
    display: block;
    font-size: 2.0vh;
    align-self: center;
    color: #fff;
    pointer-events: none;
    text-shadow: 1px 1px rgba(0,0,0,0.5);
    text-transform: uppercase;
    user-select: none;
  }
}

@media (orientation: portrait) {

  .panels.product-info .floating-product-info,
  .panels.products .floating-product-info,
  .panels.scenes .floating-product-info,
  .panels.share .floating-product-info
  {
      visibility: hidden;
  }

  .product-name {
      bottom: 10px;
      right: 25px;
  }

}
