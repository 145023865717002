.choose-source {
    display: flex;
    flex-direction: column;
}

.choose-source-title {
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 2em;
}

.fullscreen-grid {
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: stretch;
    padding: 20px;
}

.fullscreen-column {
    padding: 20px;
}

.fullscreen-column.upload {
    display: flex;
    flex-direction: column;
}

.fullscreen-column.upload .photo-upload .upload-button {
    font-size: 30vh;
    display: flex;
    margin-top: -60px;
    cursor: pointer;
}

.photo-instructions {
    font-size: 1.5em;
    flex: 0 1 80px;
}

.photo-instructions-mobile {
    display: none;
}

.photo-upload {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fullscreen-column.samples {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scenes-instructions {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.choose-source .scenes {
    overflow-y: auto;
    position: relative;
    height: calc(var(--app-height) - 200px);
}

.choose-source .scene-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choose-source .scene-picker-title {
    font-size: 1.5em;
}

.choose-source .scene-listing {
    position:relative;
    box-sizing: border-box;
    width: 50vw;
    overflow-x: auto;
}

.choose-source .scene-listing-content {
    display: flex;
    flex-direction: row;
}

.choose-source .scene-listing-item {
    padding: 30px;
    cursor: pointer;
}

.choose-source .scene-listing-image {
    height: 20vh;
}

.choose-source .scene-listing-title {

}

@media (orientation: portrait) {
    .container {
        position: unset;
    }

    .choose-source .fullscreen-grid {
        flex-direction: column;
        margin-top: 30px;
    }

    .choose-source .fullscreen-column.upload .photo-upload .upload-button {
        font-size: 50vw;
    }

    .choose-source .photo-instructions-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 2.0em;
    }

    .choose-source .scenes {
        overflow-x: auto;
        position: relative;
        height: unset;
    }

    .choose-source .scenes-instructions {
        display: none;
    }

    .choose-source .scene-listing {
        width: 100%;
    }

    .choose-source .scene-listing-item {
        padding: 0;
    }

    .choose-source .scene-listing-image {
        width: 100%;
        height: unset;
    }

    .choose-source .photo-instructions {
        display: none;
    }

    .choose-source .scene-listing-content {
        flex-direction: column;
    }
}