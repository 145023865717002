.scene-options {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000 !important;
}

.scene-options * {
    z-index: 1000 !important;
}

.scene-options .MuiButtonBase-root {
    margin: 3px !important;
}

.scene-options #Tools-actions {
    padding: 0 !important;
    margin: 5px 0 !important;
    white-space:nowrap;
}

